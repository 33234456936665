<template>
  <div class="content">
    <div
      class="main-screen-section inside"
      style="
        background: url('img/screen1.jpg') center top no-repeat;
        background-size: cover;
      "
    ></div>

    <div class="container inside-page content-section">
      <h1>Головные боли и мигрень<ReferenceLink text="1" href="#ref1" /></h1>
      <div class="content-text text-size-big">
        <p>
          Головная боль входит в число самых распространенных расстройств
          нервной системы <br class="visible-xs" />
          и существенно нарушает качество жизни и трудоспособность.<br />
        </p>
        <p>
          Регулярные головные боли ассоциируются с личным и общественным
          бременем боли, инвалидностью, сниженным качеством жизни и финансовыми
          расходами. Однако при этом во всем мире головные боли редко
          диагностируются,<br />
          а пациенты не получают соответствующей терапии.
        </p>

        <div class="img-block">
          <img src="img/img1.png" class="img-responsive" alt="img" />
        </div>
      </div>
      <h3>
        Классификация головных болей<ReferenceLink text="2" href="#ref2" />
      </h3>
      <div class="content-text text-size-middle">
        <p>
          Все виды головных болей делятся на эпизодические и хронические —
          <br class="visible-xs" />
          в зависимости от частоты приступов.
        </p>
        <p>
          Международное общество головной боли (IHS — International Headache
          Society) предлагает следующую классификацию головных болей:
        </p>
        <div class="row">
          <div class="col-md-4">
            <div class="list-content">
              <div class="sub-list-title">
                <span class="semibold">Первичные головные боли</span> —
                <br class="visible-xs" />
                не обусловлены другим заболеванием
              </div>
              <ul class="list-block">
                <li>Мигрень</li>
                <li>Головная боль напряжения</li>
                <li>
                  Тригеминальные <br />
                  вегетативные цефалгии
                </li>
                <li>Другие первичные головные боли</li>
              </ul>
            </div>
          </div>
          <div class="col-md-4">
            <div class="list-content">
              <div class="sub-list-title">
                <span class="semibold">Вторичные головные боли</span> —
                <br class="visible-xs" />
                обусловлены другим заболеванием
              </div>
              <ul class="list-block">
                <li>Инфекция</li>
                <li>Нарушения гомеостаза</li>
                <li>Поражения сосудов головного мозга и шеи</li>
                <li>Несосудистые внутричерепные расстройства</li>
                <li>Травма головы или шеи</li>
                <li>Психические расстройства</li>
                <li>Эффект вещества или его отмены</li>
                <li>Патологии черепа, шеи, глаз и т.д.</li>
              </ul>
            </div>
          </div>
          <div class="col-md-4">
            <div class="list-content">
              <div class="sub-list-title">
                <span class="semibold">Другие</span> — не подходящие
                <br class="hidden-xs" />
                под критерии первичных и вторичных головных болей
              </div>
              <ul class="list-block">
                <li>Краниальные невралгии</li>
                <li>Центральная и первичная лицевая боль</li>
                <li>Другие головные боли</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ReferencesList :list="referencesList" />
    </div>
  </div>
</template>

<script>
import ReferenceLink from '../components/TevaReference/ReferenceLink.vue'
import ReferencesList from '../components/TevaReference/ReferencesList.vue'
export default {
  name: 'Home',
  components: {
    ReferenceLink,
    ReferencesList
  },
  metaInfo: {
    title: 'Головные боли',
    meta: [
      {
        name: 'description',
        content:
          'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность'
      }
    ]
  },
  data() {
    return {
      referencesList: [
        {
          id: 'ref1',
          text: 'Seddik AH, Branner JC, Ostwald DA, Schramm SH, Bierbaum M, Katsarava Z. The socioeconomic burden of migraine: An evaluation of productivity losses due to migraine headaches based on a population study in Germany Cephalalgia. 2020;40:1551–6'
        },
        {
          id: 'ref2',
          text: 'Headache Classification Committee of the International Headache Society : Cephalalgia. – 2018. – No 38 (1): 1-211 е'
        }
      ]
    }
  }
}
</script>
